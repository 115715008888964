//Preparing for AF pages (smarty/templates/pfaf-*)

var wipwrp = window.wipwrp || {};

wipwrp.pfaf = (function(doc,win) {

    let self = {};
    let deferredPrompt;
    let btnContainer;
    let installBtn;
    let cancelBtn;
    let closeTriggered=false;
    const hiddenClass = 'hidden';

    self.init = function () {
        btnContainer = doc.querySelector('#install-to-home');
        installBtn = btnContainer?.querySelector('[data-install-to-home]');
        cancelBtn = btnContainer?.querySelector('[data-install-close]');
        _setPWAInstall();
    }

    function _setPWAInstall(){
        if (!installBtn)return;
        if ('serviceWorker' in navigator) {
            window.addEventListener('load', function() {
                navigator.serviceWorker.register('/service-worker.js').then(()=> {
                    console.log('ServiceWorker reg success');
                }, (err)=>{
                    console.error('SW reg failed: ', err);
                })
            });
        }
        win.addEventListener('beforeinstallprompt', (e) => {
            console.log(e)
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault();
            // Stash the event so it can be triggered later.
            deferredPrompt = e;
            // Update UI notify the user they can install the PWA
            if (!closeTriggered){
                showInstallPromotion();
            }
            // Optionally, send analytics event that PWA install promo was shown.
            console.log(`'beforeinstallprompt' event was fired.`);
        });
    }
    function showInstallPromotion(){
        btnContainer.classList.remove(hiddenClass);
        cancelBtn.addEventListener('click',(e)=>{
            btnContainer.classList.add(hiddenClass);
            closeTriggered = true;
        })
        installBtn.addEventListener('click',(e)=>{
            btnContainer.classList.add(hiddenClass);
            deferredPrompt.prompt();
            deferredPrompt.userChoice
            .then((choiceResult)=>{
                console.log(choiceResult.outcome)
                if (choiceResult.outcome === 'accepted'){
                    console.log('User accepted A2HS prompt')
                }else{
                    console.log('User dismissed A2HS prompt')
                }
                deferredPrompt = null;
                closeTriggered = true;
                btnContainer.classList.add(hiddenClass);
            })
        })
    }

    return self;
})(document, window);
